import { render, staticRenderFns } from "./ScanFour.vue?vue&type=template&id=604cef84&scoped=true&"
import script from "./ScanFour.vue?vue&type=script&lang=js&"
export * from "./ScanFour.vue?vue&type=script&lang=js&"
import style0 from "./ScanFour.vue?vue&type=style&index=0&id=604cef84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "604cef84",
  null
  
)

export default component.exports