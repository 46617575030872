<template>
    <div class="container">
      <div class="containerTit">{{item.pageTitle ? item.pageTitle : '设备详情'}}</div>
      <!--第一块-->
      <div class="blockWrap">
        <div class="blockWrapTit"><span class="dot"></span>{{item.title}}</div>
        <div class="blockWrapInner">
          <template v-if="item.useType == 2">
            <div>
              <div class="itemTit itemTit0" v-html="item.subTitle"></div>
              <div class="itemVal itemVal0" v-html="item.titleValue"></div>
            </div>
          </template>
          <div class="borderBox">
            <div class="w50" v-for="item1 in item.mainPar" :key="item1.title">
              <div class="itemTit" v-html="item1.dynamic?getNameCh(item1.nameEn):item1.title"></div>
              <div class="itemVal" v-html="item1.dynamic?'-':item1.content"></div>
            </div>
          </div>
          <draggable v-model="item.subPar">
            <div class="normalBox" v-for="item1 in item.subPar" :key="item1.title">
              <div class="itemTit" v-html="item1.title"></div>
              <div class="itemVal" v-html="item1.content"></div>
            </div>
          </draggable>
        </div>
      </div>

      
      <template v-if="item.useType == 2">
        
         <div class="blockWrap" :key="index2">
            <div class="blockWrapTit">
              <span class="dot"></span>
                {{item.parDetail.title}}
            </div>
            <draggable v-model="item.parDetail.list">
            <div class="blockItemWrap" v-for="(item2,index2) in item.parDetail.list" :key="index2">
              <div class="parInner">
                <div class="parInnerTit">{{item2.c1}}</div>
                <div class="parInnerItem">
                  <span class="hgTit">合格指标</span>
                  <span class="hgValue" v-html="item2.c2"></span>
                </div>
                <div class="parInnerItem">
                  <span class="hgTit">检验结果</span>
                  <span class="hgValue" v-html="item2.c3"></span>
                </div>
              </div>
            </div>
            </draggable>

        </div>


        <!--图文介绍-->
        <div class="blockWrap pd10" v-if="item.useType == 2">
          <img class="tpImg" :src="item.reportImg" />
          <div class="itemVal tpInfo" v-html="item.devInfo"></div>
        </div>

        <!--文档类-->
        <div class="blockWrap pd10">
          <draggable v-model="item.docList">
            <div v-for="(item3,index3) in item.docList" :key="index3" class="docItemWrap">
              <div class="docLeft">
                <img :src="item3.icon" class="icon" />
                <span>{{item3.title}}</span>
              </div>
              <img class="rtIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/jt%402x%20(2).png" />
            </div>
          </draggable>
        </div>
        <!--联系客服-->
        <div class="downBtn" v-if="item.useType == 2">{{item.text}}</div>
      </template>
      
      <template v-else>
        <div class="blockWrap blockWrapInner">
          <div class="blockWrapItem" v-for="(item1, index) in item.parDetail" :key="item1.title">
            <div class="normalBox normalBox2">
              <div class="itemTit">{{item1.title}}</div>
              <div class="itemVal">
                <template v-if="item1.type == 1">
                  <span @click="changeVisible(index)">
                    <img v-if="getName(index)" class="jt_down" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/jt.png" />
                    <img v-else class="jt_up" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/jt.png" />
                  </span>
                </template>
                <template v-else>
                  {{item1.value}}
                </template>
              </div>
            </div>
            <template v-if="item1.list">
              <div class="innerCard" v-show="getName(index)">
              <template v-if="item.useType == 2">
                <div class="innerCardTop">
                  <span>检验项目</span>
                  <span style="padding-right: 20px;">合格指标</span>
                  <span>检验结果</span>
                </div>
              </template>
              <template v-else>
                <div class="innerCardTop">
                  <span>项目</span>
                  <span style="padding-right: 20px;">实测值</span>
                  <span>要求值</span>
                </div>
              </template>
                <div class="innerCardBody">
                  <div class="innerCardItem" v-for="innerItem in item1.list" :key="innerItem.c1">
                    <span class="c1" v-html="innerItem.c1"></span>
                    <span class="c2" v-html="innerItem.c2"></span>
                    <span class="c3" v-html="innerItem.c3"></span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="blockWrap pd10">
         <div class="normalBox">
             <div class="itemTit">监测报告</div>
             <div class="itemVal"><img class="reportImg" :src="item.reportImg" /></div>
         </div>
      </div>
      <div class="downBtn">下载报告</div>
      </template>
     
      
    </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    modelArray:{
      type: Array,
      default: ()=>{return []}
    }
  },
  components:{
    draggable
  },
  data() {
    return {
      Block1Visible: false,
      Block2Visible: false,
      Block3Visible: false,
      Block4Visible: false,
      Block5Visible: false,
      Block6Visible: false,
      Block7Visible: false,
      Block8Visible: false,
      Block9Visible: false,
      Block10Visible: false,
    }
  },
  methods:{
    getNameCh(nameEn){
      let array = this.modelArray.filter(item=>item.nameEn===nameEn);
      return  array.length>0?array[0].nameCh:'-'
    },
    getName(index) {
      let name = 'Block'+(index+1)+'Visible'
      return this[name]
    },
    changeVisible(index) {
      let name = 'Block'+(index+1)+'Visible'
      this[name] = !this[name]
    }
  }
}
</script>
<style scoped>
.container {
  background: #F8FAFC url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/topBg.png') top center no-repeat;
  background-size: 100% 240px;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
}
.containerTit{
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  padding: 30px 0;
}
.blockWrap{
  width: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.blockWrapInner{
  padding: 0 10px;
}
.blockWrapTit{
  font-size: 16px;
  font-family: PingFang SC;
  color: #161B2E;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #E7F5F6;
  padding: 10px 0;
}
.blockWrapTit .dot{
  width: 6px;
  height: 6px;
  background: #4BC3C8;
  border-radius: 50%;
  display: block;
  margin: 0 8px 0 10px;
}
.borderBox {
  border-bottom: 1px dashed #DDE9EA;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemTit{
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #9498A6;
  padding: 5px 0;
  text-align: left;
}
.itemVal{
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #161719;
  padding: 5px 0;
}
.w50{
  width: 50%;
  box-sizing: border-box;
  text-align: left;
}
.borderBox .w50:nth-child(1) .itemVal{
  color: #1EBAC1;
}
.normalBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.normalBox2{
  padding: 10px 0;
}
.normalBox .itemVal, .normalBox.itemVal{
  padding: 0;
}
.jt_up{
  transform: rotate(180deg)
}
.jt_up, .jt_down{
  width: 15px;
  height: 8px;
}
.downBtn{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  padding: 15px 0;
  background: #4BC3C8;
  box-shadow: 1px 2px 3px 0px rgba(207,235,237,0.82);
  border-radius: 25px;
  margin: 0 auto;
}
.innerCard{
  border-radius: 10px;
  overflow: hidden;
  background: #F8FBFE;
}
.innerCardTop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #E7F5F6;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #161B2E;
  padding: 12px 10px;
}
.innerCardBody{
  padding: 0 8px;
}
.innerCardItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #161719;
  padding: 10px 0;

}
.innerCardItem .c1{
  color: #6E7C7F;
  width: 80px;
  text-align: left;
}
.innerCardItem .c2 {
  width: 80px;
  text-align: center;
}
.innerCardItem .c3{
  width: 130px;
  text-align: right;
}
.reportImg{
  width: 124px;
  display: block;
}
.pd10{
  padding: 10px;
}
.tpImg{
  width: 100%;
  border-radius: 10px;
}
.tpInfo{
  text-align: center;
  line-height: 25px;
}
.itemTit0{
  text-align: left;
}
.itemVal0{
  text-align: left;
  font-weight: bold;
}
</style>
<style>
.innerCardItem .myImg {
  width: 37px!important;
  height: 19px!important;
}
.parInner{
  background: #F8FBFE;
  border-radius: 20px;
  padding: 0 6px;
}

 .parInnerTit{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #161B2E;
  text-align: left;
  border-bottom: 1px solid #DDE9EA;
  padding: 20px 0 17px;
  margin-bottom: 5px;
}
.parInnerItem{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.parInnerItem .hgTit{
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #6E7C7F;
  line-height: 33px;
}
.parInnerItem .hgValue{
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #161719;
}
.blockItemWrap{
  background: #FFFFFF;
  border-radius: 10px;
  margin: 20px 10px 0px;
  padding-bottom: 5px;
}
.docItemWrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.docLeft{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #161B2E;
  line-height: 33px;
}
.docLeft .icon{
  width: 16px;
  margin-right: 13px;
  margin-left: 10px;
}
.rtIcon{
  width: 8px;
}
</style>