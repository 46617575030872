<template>

    <div class="ewmBoxWrap" :style="[{backgroundColor:data.bgColor},{ padding: data.paddTop+'px' +' '+ data.paddLeft+'px' },{ backgroundImage: `url(${data.picList[0].url})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
       <div class="ewmBoxTitle">防伪二维码</div>
       <div class="ewmBox">
         <!--左边-->
         <template v-if="data.scanStyle === 1">
          <div class="ewmLeftBox">
            <div class="ewmImgBox">
              <vue-qr
                :size="115"
                :margin="0"
                :auto-color="true"
                :dot-scale="1"
                :text="codeUrl"
              />
              <div class="ewmImgCode">{{handle}}</div>
            </div>
          </div>
         </template>
         <template v-else-if="data.scanStyle === 2">
           <div class="ewmLeftBox2">
            <div class="ewmImgBox2">
              <vue-qr
                :size="80"
                :margin="0"
                :auto-color="true"
                :dot-scale="1"
                :text="codeUrl"
              />
            </div>
           </div>
         </template>
        <template v-else>
          <div class="ewmLeftBox">
            <div class="ewmImgBox">
              <vue-qr
                :size="100"
                :margin="0"
                :auto-color="true"
                :dot-scale="1"
                :text="codeUrl"
              />
              <div class="ewmImgCode">{{handle}}</div>
            </div>
          </div>
        </template>
        <!--右边-->
        <div class="ewmRightBox">
              <div class="chinaTitle">国家标识 专利防伪</div>
              <div class="veridicalTitle">防伪验证</div>
              <div class="myVerBox">
               <input v-model="veridicalCode" placeholder="请输入验证码" />
               <button @click="toVeridical" :style="{backgroundColor: `${data.btnBgColor}`}">验证</button>
              </div>
          <div class="errTip" v-if="errTip">请输入验证码进行验证</div>
          <!--验证结果-->
          <div class="verRes">
            <div v-if="verState === 1">
              <span class="red">您好，验证成功，您购买的是正品,请放心使用！</span>
            </div>
            <div v-if="verState === 2">
              <span class="red">您好，验证码无效，请谨防假冒！</span>
            </div>
          </div>
          <!--右边底下信息-->
          <template v-if="data.scanStyle === 2">
            <div class="myboxWrap">
              <div class="myBox1"><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/num1.png" />对比实物标签与图片</div>
              <div class="myBox2"><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/num2.png" />观察图片中的文字、位置、编码是否相符</div>
              <div class="tipMsg myBox3"><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/scanZi.png"/></div> 
            </div>
          </template>
        </div>

       </div>
       <template v-if="data.scanStyle === 1">
        <div class="verifyBox">
          <div class="topMsg">
            <p>
              第一次验证时间: <span class="text">---</span>
            </p>
            <p>
              第一次验证地点: <span class="text">---</span>
            </p>
          </div>
          <div class="centerMsg">
            <p>
              <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/query.png"/>
              现在是第<span class="text">---</span>次验证
            </p>
          </div>
          <div class="bottomMsg">
            <div class="tipsBox">
              <p>
                <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/mTwo.png" /> 比对实物标签与照片:
              </p>
              <p>
                <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/mOne.png" /> 观察图片中的文字、位置、编码是否相符。
              </p>
              <p class="tipText">以上特征均相符，则是真品，否则谨防假冒。</p>
            </div>

            <div class="qklBox">
              <div class="box1">
                <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/interImg.png"/>
                <div class="qklRightBox">
                  <p class="qklMsg1">工业互联网标识码：</p>
                  <p class="qklMsg2">86.122.2/da8hw6dw</p>
                </div>
              </div>
              <div class="box2">由国家工信部授权工业互联网标识解析二级节点(86.122)签发</div>
            </div>
            </div>
        </div>
       </template>
       <template v-else-if="data.scanStyle === 2">
         <!-- <div class="style2_msgBox1">
          <div><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/1.1.png" />对比实物标签与图片</div>
          <div><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/2.2.png" />观察图片中的文字、位置、编码是否相符</div>
          <div class="tipMsg">以上特征均相符，则是真品，否则谨防假冒</div> 
         </div> -->
         <div class="style2_msgBox2">
           <div><img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/interImg.png" /></div>
           <div class="rightContent">
             <p class="rightText1">工业互联网标识码：</p>
             <p class="rightText2">{{handle? handle : '---'}}</p>
           </div>
         </div>
         <div class="style2_msgBox3">由国家工信部授权工业互联网标识解析二级节点（86.122）签发</div>
         <div class="style2_msgBox4">上次查询时间为：<span class="red">-----</span></div>
         <div class="style2_msgBox5">上次查询地点为：<span class="red">-----</span></div>
         <div class="style2_msgBox6"><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/fdj.png" />
          现在是第<span class="red">--</span>次查询
         </div>
         <div class="style2_msgBox7">*若查询扫码次数过多，标识码可能被盗用，谨防假冒产品！</div>
       </template>
       <template v-else>
        <div class="verifyBox">
          <div class="topMsg">
            <p>
              第一次验证时间: <span class="text">---</span>
            </p>
            <p>
              第一次验证地点: <span class="text">---</span>
            </p>
          </div>
          <div class="centerMsg">
            <p>
              <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/query.png"/>
              现在是第<span class="text">---</span>次验证
            </p>
          </div>
          <div class="bottomMsg">
            <div class="tipsBox">
              <p>
                <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/mTwo.png" /> 比对实物标签与照片:
              </p>
              <p>
                <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/mOne.png" /> 观察图片中的文字、位置、编码是否相符。
              </p>
              <p class="tipText">以上特征均相符，则是真品，否则谨防假冒。</p>
            </div>

            <div class="qklBox">
              <div class="box1">
                <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/interImg.png"/>
                <div class="qklRightBox">
                  <p class="qklMsg1">工业互联网标识码：</p>
                  <p class="qklMsg2">86.122.2/da8hw6dw</p>
                </div>
              </div>
              <div class="box2">由国家工信部授权工业互联网标识解析二级节点(86.122)签发</div>
            </div>
            </div>
        </div>
       </template>

         
      </div>

</template>

<script>

import VueQr from 'vue-qr'
// import $ from 'jquery'
export default {
  name: 'ScanThree',
  components: {
    VueQr
  },
  props: {
    data: {
      type: Object
    },
    verState: Number,
    handleLink: String,
    handle: String,
    query: {
      type: String
    },
    h: {
      String
    },
    codeData:{
      type: Object
    }
  },
  data() {
    return {
      codeUrl: '',
      veridicalCode: '', // 防伪码
      errTip: false,
      // verState: 0, // 0 未验证 1 验证成功 2 验证失败
    }
  },
  methods: {
    setCode() {
      if(this.handleLink) {
        this.codeUrl = this.handleLink
      }else {
        this.codeUrl = 'https://api.86122m.com/h?h=aaamz'
      }
    },
    // 点击验证
    toVeridical() {
      if(!this.veridicalCode.trim()) {
        this.errTip = true
        this.$emit('veridicalCode', this.veridicalCode)
      }else {
        this.errTip = false
        // 调接口
        this.$emit('veridicalCode', this.veridicalCode)
      }
    }
  },
  mounted() {
    this.setCode()
  }
}
</script>

<style scoped>

  .ewmBoxWrap {
    width: 100%;
    padding-top: 50px;
    height: auto;
    font-size: 16px;
    /* background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/backHe.png'); */
    background-size: cover;
  }
  .ewmBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px 0;
  }

  .ewmRightBox{
    width: 70%;
    height: auto;
    display: border-box;
  }
  .ewmRightBox input {
    border: 1px solid #aaa;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    height: 27px;
    line-height: 27px;
    /* margin: 5px 5px 5px 1px; */
    padding-left: 5px;
    font-size: 0.6rem;
    text-align: left;
    padding-right: 50px;
    display: border-box;
    border-right: none;
  }
  .ewmRightBox button{
    width: 50px;
    height: 26px;
    text-align: center;
    line-height: 27px;
    font-size: 0.6rem;
    background: #0b667c;
    color: #fff;
    /* margin: 5px 0; */
    border-radius: 5px;
    display: inline-block;
    outline: none;
    border: none;
  }
  .errTip{
    font-size: 12px;
    color: #f00;
    text-align: left;
    line-height: 20px;
    padding-left: 10px;
  }
  .verRes{
    font-size: 12px;
    color: #f00;
    text-align: left;
    line-height: 20px;
    padding-left: 10px;
    height: 40px;
  }
  .veridicalTitle{
    font-size: 0.86rem;
    text-align: center;
    position: relative;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    height: 30px;
    line-height: 30px;
  }
  .veridicalTitle:before, .veridicalTitle:after {
    content: '';
    position: absolute;
    top: 50%;
    background: #acacac;
    width: 10%;
    height: 1px;
  }
  .veridicalTitle:before{
    left: 10%;
  }
  .veridicalTitle:after {
    right: 10%;
  }
  .chinaTitle{
    font-size: 1.1rem;
    color: #1F2224;
    text-align: center;
    font-weight: bolder;
    /* margin: 25px 0 0; */
    font-family: cursive;
    line-height: 24px;
  }

  /**风格一**/
  .ewmLeftBox{
    width: 50%;
    height: auto;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/mSignIcon.png');
    background-size: 100% 100%;
    height: 277px;
    min-height: 170px; 
    display: border-box;
  }
  .ewmLeftBox .ewmImgBox{
    margin-top: 116px;
  }
 .topMsg p{
    text-align: left;
    line-height: 30px;
    color: #000;
    padding-left: 15px;
    background-color: #c8d6da;
    width: 96%;
    font-size: 0.66rem;
    margin: 6px auto;
  }
  .topMsg p span {
    color: #055167;
  }
  .centerMsg p{
    text-align: left;
    font-size: 0.7rem;
    line-height: 30px;
    padding-left: 10px;
    color: #000;
  }
  .centerMsg p img{
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .centerMsg p span{
    color: #055167;
    font-size: 1rem;
  }
  .tipsBox
  {
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 20px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/bjKuang.png') top left no-repeat;
    background-size: 100% 100%;
  }
  .tipsBox p {
    font-size: 0.66rem;
    color: #fff;
    text-align: left;
    line-height: 30px;
    padding-left: 10px;
  }
  .tipsBox p img {
    width: 25px;
    height: 25px;
    vertical-align: top;
  }
  .tipsBox .tipText{
    font-weight: bolder;
  }
  .qklBox {
    margin-top: 10px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/bjQkl.png') top left no-repeat;
    background-size: 100% 100%;
    padding-left: 20px;
    padding-top: 20px;
  }
  .qklBox .box1{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }
  .qklBox .box1 img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: top;
  }
  .qklRightBox .qklMsg1{
    color: #fff;
    font-size: 0.6rem;
    text-align: left;
  }
  .qklRightBox .qklMsg2{
    color: #fff;
    font-weight: 700;
     font-size: 0.6rem;
     text-align: left;
  }
  .qklBox .box2{
    font-size: 12px;
    color: #fff;
    line-height: 20px;
    width: 98%;
    padding: 5px 0 10px;
  }
  /***风格二***/
  .ewmLeftBox2{
    width: 45%;
    margin-left: 2%;
    margin-right: 2%;
    background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bq2%402x.png');
    background-size: 100% 100%;
    height: 220px;
    min-height: 170px; 
    display: border-box;
  }
  .ewmLeftBox2 .ewmImgBox2 {
    margin-top: 90px;
  }
  .ewmLeftBox .ewmImgBox2 .ewmImgCode2{
    text-align: center;
    font-size: 12px;
    color: #333;
    line-height: 25px;
  }
  .style2_msgBox1{
    font-size: 14px;
    font-weight: 400;
    color: #211F1F;
    text-align: left;
    margin-bottom: 20px;
  }
  .style2_msgBox1 img {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
  }
  .style2_msgBox1 .tipMsg{
    font-size: 12px;
    font-weight: 500;
    color: #211F1F;
    padding-left: 10px;
  }
  .style2_msgBox2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    border-bottom: 1px dashed #0A566D;
    width: 80%;
  }
  .style2_msgBox2 img {
    width: 30px;
    height: 30px;
  }
  .style2_msgBox2 .rightContent .rightText1{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #3E362C;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
  }
  .style2_msgBox2 .rightContent .rightText2{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #242326;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
  }
  .style2_msgBox3, .style2_msgBox7{
    font-size: 12px;
    font-weight: 400;
    color: #B49B51;
    line-height: 20px;
    text-align: left;
    padding-left: 10px;
  }
  .style2_msgBox4, .style2_msgBox5{
    font-size: 12px;
    font-weight: 400;
    color: #3E362C;
    padding: 3px 5px;
    text-align: left;
    background: rgba(148, 159, 177, 0.2);
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .red{
    color: #f00;
  }
  .style2_msgBox6{
    font-size: 14px;
    font-weight: 400;
    color: #3E362C;
    text-align: left;
    padding-left: 10px;
  }
  .style2_msgBox6 img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .ewmBoxTitle{
    font-size: 20px;
    text-align: center;
    padding: 20px 0;
  }
  .myBox1{
    font-size: 12px;
    color: #211F1F;
    text-align: left;
    vertical-align: middle;
    padding: 5px 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .myBox2{
    font-size: 12px;
    color: #211F1F;
    text-align: left;
    vertical-align: middle;
    padding: 5px 0 0 10px;
  }
  .myBox1 img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }

  .myBox2 img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }
  .myBox2{
    font-size: 12px;
    color: #211F1F;
  }
  .myBox3 img {
    width: 90%;
  }
  .myboxWrap{
    border: 1px dashed #CCCCCC;
    border-radius: 10px;
    padding: 5px;
    width: 90%;
  }
  .myVerBox{
    position: relative;
    /* border: 1px solid #f00; */
    width: 90%;
    text-align: left;
  }
  .myVerBox button {
    position: absolute;
    right: 0;
    top: 1px;
    height: 27px;
    background-color: #5092FF;
  }
</style>
