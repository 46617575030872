<template>
  <div>
    <van-swipe
      :autoplay="3000"
      indicator-color="white"
    >
      <van-swipe-item v-for="(picItem,index) in item.picList" :key="picItem.url+index">
        <van-image
          :src="picItem.url"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
import { Image as VanImage, Swipe, SwipeItem } from 'vant'

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped>
.van-swipe-item{
  /* float: left !important; */
  display: inline-block;
}
.van-image img{
  width: 100%
}
</style>
