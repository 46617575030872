<template>

    <div class="ewmLeftBox2" :style="[{height: data.scanBgHeight + 'px'},{background: data.bgColor},{display: 'flex',alignItems: 'center', justifyContent: data.align}]">
        <div class="ewmImgBox2" :style="{padding: data.paddTop+'px' +' '+ data.paddLeft+'px'}">
            <vue-qr
                    :size="80"
                    :margin="0"
                    :auto-color="true"
                    :dot-scale="1"
                    :text="codeUrl"
            />
        </div>
        <div class="yzCode"></div>
    </div>

</template>

<script>

    import VueQr from 'vue-qr'
    // import $ from 'jquery'
    export default {
        name: 'ScanFive',
        components: {
            VueQr
        },
        props: {
            data: {
                type: Object
            },
            verState: Number,
            handleLink: String,
            handle: String,
            query: {
                type: String
            },
            h: {
                String
            },
            codeData:{
                type: Object
            }
        },
        data() {
            return {
                codeUrl: '',
                veridicalCode: '', // 防伪码
                errTip: false,
                // verState: 0, // 0 未验证 1 验证成功 2 验证失败
                txmDialogVisible: false,
            }
        },
        methods: {
            setCode() {
                if(this.handleLink) {
                    this.codeUrl = this.handleLink
                }else {
                    this.codeUrl = 'https://api.86122m.com/h?h=aaamz'
                }
            },
            
        },
        mounted() {
            this.setCode()
        },
        computed: {
            
        }
    }
</script>

<style scoped>
</style>
