<template>
  <div class="scanWrap"
    :style="[{ padding: data.paddTop+'px' +' '+ data.paddLeft+'px'}, {backgroundColor:data.bgColor}, {backgroundImage: `url(${data.boxBg})`}]"
    >
    <p :style="[{fontSize: data.subTitleSize+'px'},{textAlign: data.titleAlign}, {color: data.subTitleColor}]">
      {{data.subTitle}}
    </p>
    <h6 :style="[{fontSize: data.titleSize+'px'},{textAlign: data.titleAlign}, {color: data.titleColor}]">
      {{data.title}}
    </h6>
    <div class="codeDiv">
      <div class="codeBlockWrap" :style="[{ backgroundImage: `url(${data.scanBg})`}, {height: data.scanBgHeight + 'px'}, {width: data.scanBgWidth + 'px'}]">
		  <div :style="[{marginTop: data.scanTop + 'px'},{marginLeft: data.scanLeft + 'px'}]">
			  <vue-qr
			    :size="90"
			    :margin="0"
			    :auto-color="true"
			    :dot-scale="1"
			    :text="codeUrl"
			  />
		  </div>
        
        <div class="paramscode_id">
          {{handle ? handle : '???'}}
        </div>
      </div>
      <img style="width: 180px; height: 120px;" src="../../../assets/images/coustomPage/sn/arrow.png" />
    </div>
    <div class="identBox">
      <p><img src="../../../assets/images/coustomPage/sn/1.png" />比对实物标签与图片</p>
      <p><img src="../../../assets/images/coustomPage/sn/2.png" />观察图片中的文字、位置、编码是否相符</p>
      <p class="tip" style="color: #E36418; font-size: 12px;">以上特征均相符，则是真品，否则谨防假冒</p>
      <div class="codeMsg">
        <img src="../../../assets/images/coustomPage/sn/codeLogo.png" style="width:25px; height: 25px;"/>
        <div>
          <p>工业互联网标识码：</p>
          <p class="pCode">{{handle}}</p>
        </div>
      </div>
      <p style="color: #AB924E; padding: 0; margin: 10px 0; font-size: 12px;">由国家工信部授权工业互联网标识解析二级节点(86.122)签发</p>
      <div class="searchMsg">
        <p>第一次查询时间为：<span class="codeTime" style="color: #E36418">---</span></p>
        <p>第一次查询地点为：<span class="codeAddress" style="color: #E36418">---</span></p>
        <p><img src="../../../assets/images/coustomPage/sn/search.png" />这是第<span class="codeNumber" style="color: #E36418">---</span>次数</p>
      </div>
    </div>
  </div>
</template>

<script>

import VueQr from 'vue-qr'
// import $ from 'jquery'
export default {
  name: 'ScanOne',
  components: {
    VueQr
  },
  props: {
    data: {
      type: Object
    },
    handleLink: String,
    handle: String,
  },
  data() {
    return {
      codeUrl: '标识'
    }
  },
  methods: {
    getQueryString(name, href) {
      // eslint-disable-next-line no-sparse-arrays
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(href) || [, ''])[1].replace(/\+/g, '%20')) || null
    },
    setCode() {
      if(this.handleLink) {
        this.codeUrl = this.handleLink
      }else {
        this.codeUrl = 'https://api.86122m.com/h?h=aaamz'
      }
    },
    getCodeData(id) {
      console.log(`根据标识码：${id}发送请求`)
    }
  },
  mounted() {
    this.setCode()
  }
}
</script>

<style scoped lang="scss">
	
		* {
			box-sizing: content-box;
		}
.scanWrap{
  background-size: 100% 100%;
  > h6 {
    margin: 0 0 20px 0;
    padding: 0;
  }
  > p {
    margin: 0;
    padding: 0;
    margin: 6px 0;
  }
  .codeDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .codeBlockWrap{
      width: 120px;
      height: 100px;
      margin-right: 15px;
      position: relative;
      background-repeat: no-repeat;
      // background-image: url('../../../assets/images/coustomPage/sn/codeBg.png');
      background-size: 100% 100%;
      padding-top: 58px;
      text-align: center;
      .content{
        font-size: 12px;
        font-family: Source Han Sans CN;
        color: #2C2D2D;
        text-align: center;
        padding: 0;
      }
    }
  }
  .identBox{
    background-color: #FFE9AC;
    margin: 20px 0;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    > p{
      margin: 10px 0;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
       > img {
         margin-right: 5px;
       }
    }
    >p.tip::after{
      content: "";
      display: block;
      width: 96%;
      height: 5px;
      border-bottom: 1px dotted #E36418;
      margin: 10px auto;
    }
    .codeMsg{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items:flex-start;
      p {
        margin: 0;
        padding: 0 0 0 10px;
        font-size: 12px;
        line-height: 20px
      }
    }
  }
  .searchMsg{
    background-color: #FFDE75;
    text-align: center;
    width: 96%;
    padding: 10px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 20px;
     p {
       margin: 0;
       padding: 0;
     }
  }

}
</style>
