<template>
  <div :style="styleObject">
    <template v-if="item.type === 'image'">
      <div :style="{padding: `${item.paddTop}px ${item.paddLeft}px`, textAlign: item.align}">
        <img
          :src="item.picList[0].url"
          class="singleImg"
          :style="{width: `${item.picList[0].width}`}"
        />
      </div>
    </template>
    <template v-else>
      <div>
        <div v-for="(picItem,index) in item.picList" :key="picItem.url + index" :style="imgStyle(picItem,item)">
          <div>
            <img
              width="100%"
              :src="picItem.url"
              style="min-height: 20px; display: block;"
            />
          </div>
        </div>
      </div>
    </template>
    <div style="clear: both" />
  </div>
</template>
<style scoped>
.singleImg{
  min-height: 20px;
  display:inline-block;
  max-width: 100%;
}
</style>
<script>
import { Image as VanImage, Swipe, SwipeItem } from 'vant'

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleObject() {
      return {
        padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px'
      }
    },
    imgStyle() {
      return (n, item) => {
        var wid
        var pos
        var str
        pos = item.align
        if (n.width) {
          wid = n.width
        } else {
          wid = '100%'
        }
        str = 'width: ' + wid
        if (pos === 'left') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'right') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'center') {
          console.log('pos', pos)
          str = `width: ${wid};margin: 0 auto;`
        }

        return str
      }
      /* return {
        width:(picItem.width?picItem.width:'100%'),
        float:(picItem.width?'left':'none')
      }*/
    }
  },
  methods: {
    getItem() {
      console.log(this.item)
    }
  }
}
</script>
