<template>
  <div class="fangyuanPage">
    <div class="pb20">
      <div v-show="showPage">
        <!--证书信息页面-->
        <div v-show="selTabbar && selTabbar.title === item.tabbar[0].title">
          <div class="logoBox">
            <img :src="item.zsxx.logo" class="logo" />
          </div>
          <div class="pageTit">{{item.zsxx.title}}</div>
          <van-swipe :autoplay="2000" indicator-color="transparent" show-indicators="false" @change="onSwipeChange">
              <van-swipe-item v-for="(picItem,index) in item.zsxx.picList" :key="picItem.url+index">
                <van-image width="86%" :src="picItem.url" class="vanImgs" />
              </van-swipe-item>
            </van-swipe>
            <div class="swipeNum"><span class="swipeIndex">{{activeIndex+1}}</span>/{{item.zsxx.picList.length}}</div>
            <div class="otitle">
              <div style="width: 100%;">
                <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/yellowImg1.png" class="otitleImg"/>
                <div class="otitleTop">
                  <div class="otitleText">
                    {{item.zsxx.infoTit}}
                  </div>
                  <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/downImg.png" mode="widthFix" class="downImg" />
                  <!-- <div style="display: flex; align-items: center;">
                    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/line@2x.png" alt="" style="width: 110px;">
                    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/zk@2x.png" alt="" style="width: 20px; height: 20px;">
                  </div> -->
                </div>
                <div class="otitleBot">{{item.zsxx.infoEng}}</div>
              </div>
            </div>
            <div class="infoBody">
              <div v-for="(zItem,index) in item.zsxx.infoMain" :key="index" class="infoItem">
                <div class="infoItemInner">
                    <template v-if="zItem.type === 1">
                      <div class="infoName">{{zItem.title}}</div>
                      <div class="infoVal" :style="{color: zItem.msgColor}">{{zItem.msg}}</div>
                    </template>
                    <template v-else-if="zItem.type === 2">
                      <div class="infoName">{{zItem.title}}</div>
                      <div class="infoVal" :style="{color: zItem.msgColor}">{{zItem.msg}}</div>
                      <img :src="zItem.linkImg" class="rkImg" />
                    </template>
                    <template v-else-if="zItem.type === 3">
                      <div class="infoName infoName2">
                        <span>{{zItem.title}}</span>
                        <div class="flodBox" @click.stop="changeFlod(index, zItem.isFold)">
                          <template  v-if="zItem.isFold">
                            <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/zk.png" class="zkIcon"/>
                            <span>展开</span>
                          </template>
                          <template v-else>
                            <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/zk.png" class="sqIcon"/>
                            <span>收起</span>
                          </template>
                        </div>
                      </div>
                      <div v-for="(sItem,sIndex) in zItem.siteList" :key="sIndex" class="siteItemBox" v-show="!zItem.isFold">
                        <div class="siteHead">
                          <span>No.{{sIndex+1}}</span>
                          <div class="flex1"></div>
                        </div>
                        <div class="siteItem_item" v-for="(ssItem, ssIndex) in sItem.siteInfo" :key="ssIndex">
                          <div class="siteItemItem_name">{{ssItem.name}}</div>
                          <div v-html="ssItem.value" class="siteItemItem_val"></div>
                        </div>
                      </div>
                    </template>
                </div>
              </div>
              <!-- 一行两条数据 -->
              <div v-for="(zItem,index) in item.zsxx.infoMain2" :key="index" class="infoItem">
                <div class="infoItemInner infoItemInner2">
                  <div>
                    <div class="infoName">{{zItem.box1.title}}</div>
                    <div class="infoVal">{{zItem.box1.msg}}</div>
                  </div>
                  <div>
                    <div class="infoName">{{zItem.box2.title}}</div>
                    <template v-if="zItem.box2.status">
                          <div class="infoVal green" v-if="zItem.box2.status == 1">有效</div>
                          <div class="infoVal yellow" v-else-if="zItem.box2.status == 2">暂停</div>
                          <div class="infoVal red" v-else-if="zItem.box2.status == 3">撤销</div>
                          <div class="infoVal gray" v-else-if="zItem.box2.status == 4">过期</div>
                    </template>
                    <template v-else>
                      <div class="infoVal">{{zItem.box2.msg}}</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            
        </div>

        <!--企业简介/获证组织页面-->    
        <div v-show="selTabbar && selTabbar.title === item.tabbar[1].title">
          <div class="logoBox">
            <img :src="item.qyjj.logo" class="logo" />
          </div>
          <div class="pageTit">{{item.qyjj.title}}</div>

          <!-- <div class="newBody">
            <div class="newTitWrap">
              <div class="newTit">
                <div class="newEngTit">{{ item.qyjj.infoEng }}</div>
                <div class="newCheTit">{{ item.qyjj.infoTit }}</div>
              </div>
              <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/downImg2.png" mode="widthFix" class="downImg" />
            </div>
            <div v-html="item.qyjj.infoMsg" class="infoBody_msg infoBody_msg2"></div>
          </div> -->
          <div class="newBody" v-for="lItem in item.qyjj.listData" :key="lItem.name">
            <div class="newTitWrap">
              <div class="newTit">
                <div class="newEngTit">{{ lItem.nameEng }}</div>
                <div class="newCheTit">{{ lItem.name }}</div>
              </div>
              <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/downImg2.png" mode="widthFix" class="downImg" />
             </div>

             <template v-if="lItem.type === 1">
                <div v-html="lItem.content" class="infoBody_msg infoBody_msg2"></div>
              </template>
              <template v-else-if="lItem.type === 2">
                <img :src="lItem.contentImg" class="lcImg" mode="widthFix" />
              </template>
              <template v-else-if="lItem.type === 3">
                <img :src="lItem.contentImg" class="lcImg" mode="widthFix" />
                <div v-html="lItem.content" class="infoBody_msg infoBody_msg2"></div>
              </template>
          </div>
        </div>

        <!--体系介绍页面-->    
        <div v-show="selTabbar && selTabbar.title === item.tabbar[2].title">
          <!-- <img :src="item.txjs.logo" class="logo" />
          <div class="pageTit">{{item.txjs.title}}</div> -->

          <div class="logoBox">
            <img :src="item.txjs.logo" class="logo" />
          </div>
          <div class="pageTit">{{item.txjs.title}}</div>
          
          <img :src="item.txjs.qyImg" class="qyImg" />

          <div class="newBody" v-for="lItem in item.txjs.listData" :key="lItem.name">
            <div class="newTitWrap">
              <div class="newTit">
                <div class="newEngTit">{{ lItem.nameEng }}</div>
                <div class="newCheTit">{{ lItem.name }}</div>
              </div>
              <img :src="lItem.numImg" mode="widthFix" class="numberImg" />
             </div>

             <template v-if="lItem.type === 1">
                <div v-html="lItem.content" class="infoBody_msg infoBody_msg2"></div>
              </template>
              <template v-else-if="lItem.type === 2">
                <img :src="lItem.contentImg" class="lcImg" mode="widthFix" />
              </template>
              <template v-else-if="lItem.type === 3">
                <img :src="lItem.contentImg" class="lcImg" mode="widthFix" />
                <div v-html="lItem.content" class="infoBody_msg infoBody_msg2"></div>
              </template>
          </div>
        </div>
        <!--关于方圆-->
        <div v-show="selTabbar && selTabbar.title === item.tabbar[3].title">
          <!-- <img :src="item.gyfy.logo" class="logo" />
          <div class="pageTit">{{item.gyfy.title}}</div> -->
          <div class="logoBox">
            <img :src="item.gyfy.logo" class="logo" />
          </div>
          <div class="pageTit">{{item.gyfy.title}}</div>
          <div class="newBody" v-for="lItem in item.gyfy.listData" :key="lItem.name">
            <div class="newTitWrap">
              <div class="newTit">
                <div class="newEngTit">{{ lItem.nameEng }}</div>
                <div class="newCheTit">{{ lItem.name }}</div>
              </div>
              <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/downImg2.png" mode="widthFix" class="downImg" />
             </div>

             <template v-if="lItem.type === 1">
                <div v-html="lItem.content" class="infoBody_msg infoBody_msg2"></div>
              </template>
              <template v-else-if="lItem.type === 2">
                <img :src="lItem.contentImg" class="lcImg" mode="widthFix" />
              </template>
              <template v-else-if="lItem.type === 3">
                <img :src="lItem.contentImg" class="lcImg" mode="widthFix" />
                <div v-html="lItem.content" class="infoBody_msg infoBody_msg2"></div>
              </template>
          </div>
        </div>
      </div>
      <div v-show="!showPage" class="photosPage">
        <div class="logoBox">
          <img :src="item.xchy.logo" class="logo" />
        </div>
        <div class="pageTit">{{item.xchy.title}}</div>
        <div class="newBody">
          <div class="newTitWrap">
            <div class="newTit">
              <div class="newEngTit">{{ item.xchy.infoEng }}</div>
              <div class="newCheTit">{{ item.xchy.infoTit }}</div>
            </div>
            <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/downImg2.png" mode="widthFix" class="downImg" />
          </div>
          <div class="photoWrap newPhotosWrap">
            <img :src="item.url" v-for="(item, index) in item.xchy.photoList" :key="index" class="photoImg"/>
          </div>
        </div>

        <div class="newBody">
          <div class="newTitWrap">
            <div class="newTit">
              <div class="newEngTit">{{ item.xchy.videoEng }}</div>
              <div class="newCheTit">{{ item.xchy.videoTit }}</div>
            </div>
          </div>
          <video class="myVideo" controls="false" :poster="item.xchy.poster" object-fit="contain" :src="item.xchy.video"></video>
        </div>
        
      </div>
      <div class="fixedTabbar">
        <div v-for="tItem in item.tabbar" class="tabbarItem" :key="tItem.title" @click="changeTabbar(tItem)" :class="{'selTabbar': selTabbar && selTabbar.title === tItem.title}">
          <img :src="selTabbar && selTabbar.title === tItem.title ? tItem.selectIcon : tItem.icon" mode="heightFix" />
          <div>{{tItem.title}}</div>
        </div>
      </div>
    </div>
    <!--背后的故事-->
    <div class="fixedBtn" @click="changePage" v-show="item.showPhotos === 1">
      <img :src="item.fixImg" class="fixedBtnImg"/>
    </div>
   
  </div>
</template>
<script>
	import {
		Image as VanImage,
		Swipe,
		SwipeItem
	} from 'vant'
	export default {
		data() {
			return {
        selTabbar: null,
        activeIndex: 0,
        showPage: true,
        flodIndex: null, //记录折叠的index
			}
		},
		components: {
			[Swipe.name]: Swipe,
			[SwipeItem.name]: SwipeItem,
			[VanImage.name]: VanImage
		},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		mounted() {
      this.selTabbar = this.item.tabbar[0]
      this.$emit('fyChangeTab', this.selTabbar )
    },
    methods: {
      changeTabbar(item) {
        this.showPage = true
        if(this.flodIndex) {
          let isFlod = this.item.zsxx.infoMain[this.flodIndex].isFold
          this.item.zsxx.infoMain[this.flodIndex].isFold = !isFlod
        }
        this.selTabbar = item
        this.$emit('fyChangeTab', this.selTabbar )
      },
      onSwipeChange(event) {
        let activeIndex = event;
        this.activeIndex = activeIndex
      },
      morePhotos() {

      },
      changePage() {
        this.showPage= false
      },
      changeFlod(index,isFlod) {
        this.flodIndex = index
        this.item.zsxx.infoMain[index].isFold = !isFlod
        console.log('----- this.item.zsxx.infoMain[index].isFold-----',  this.item.zsxx.infoMain[index].isFold)
        // this.item.zsxx.infoMain[index].isFold = !this.item.zsxx.infoMain[index].isFold
      }
    }
  }
</script>
<style scoped>
.fangyuanPage{
  background: #0b2977 url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/pageBg2.png') top center no-repeat;
  background-size: 100% 868px;
  position: relative;
  padding-bottom: 91px;
}
.fixedTabbar{
  position: absolute;
  bottom: 0;
  left:0;
  right: 0;
  height: 91px;
  background: #F7F9FF;
  /* box-shadow: 0rpx 4rpx 49rpx 0rpx rgba(154,188,230,0.6); */
  display: flex;
  justify-content: space-around;
  align-items: center
}
.tabbarItem {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2A3C6C;
  text-emphasis: center;

}

.tabbarItem img{
  height: 22px;
  text-emphasis: center;
  margin-bottom: 6px;
  
}
.selTabbar{
  color: #0B2977;
  font-weight: bold;
}
.logo{
  width: 96px;
  height: 60px;
  /* margin: 0 auto; */
  margin-left: 37px;
  margin-top: 46px;
  margin-bottom: 23px;
}
.pageTit{
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fff;
  text-align: left;
  padding-bottom: 45px;
  padding-left: 37px;
}
.subTitle{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  padding: 6px 30px;
  background: #53A9A6;
  box-shadow: 0px 3px 4px 0px rgba(4,0,0,0.3);
  border-radius: 13px;
  display: inline-block;
  margin-bottom: 35px;
}
.swipeNum{
  font-size: 14px;
  font-family: DIN;
  font-weight: bold;
  color: #7F849A;
  text-align: center;
  padding: 17px 0 30px;
}
.swipeNum .swipeIndex{
  color: #313F87;
  font-size: 20px;
}
.otitle{
  /* background-image: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/fangyuanzhengshu/titBg.png'); */
  /* background-repeat: no-repeat;
  background-size: 100% 100%; */
  width: 100%;
  /* height: 80px; */
  text-align: left;
  box-sizing: border-box;
  padding: 30px 30px 33px 30px;
}
.otitleTop{
  display: flex; align-items: center; justify-content: space-between;
}
.otitleText{
  font-size: 34px; color: #fff; font-weight: 800;
}
.otitleBot{
  font-size: 12px; color: #fff;
}
.infoBody{
  width: 90%;
  margin: 0 auto;
  background: #EDF0FF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 10px 10px rgba(154,188,230,0.6) inset;
  border-radius: 10px;
  /* padding-bottom: 20px; */
  /* position: relative;
  top: -10px; */
}

.infoItem{
  padding-right: 23px;
  position: relative;
}

.infoItemInner{
  padding-left: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
  padding-bottom: 18px;
  text-align: left;
}
.infoItemInner .rkImg{
  width: 156px;
  height: 42px;
  position: absolute;
  right: 23px;
  top: 25px;
}

.infoName{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2E3033;
  padding-top: 24px;
  padding-bottom: 10px;
  text-align: left;
}

.infoName2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infoName2 .flodBox{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
}
.infoName2 .zkIcon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  transform: rotate(-90deg);
}
.infoName2 .sqIcon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.infoVal{
  text-align: left;
  font-weight: bold;
  font-size: 12px;
}
.green {
  color: #fff;
  background: #13bb76;
  display: inline-block;
  padding: 4px 9px;
  border-radius: 5px;
}
.red{
  background: #f0545b;
  color: #fff;
  display: inline-block;
  padding: 4px 9px;
  border-radius: 5px;
}
.yellow{
  background: #daca5e;
  color: #fff;
  display: inline-block;
  padding: 4px 9px;
  border-radius: 5px;
}
.gray{
  background: #ccd1dc;
  color: #fff;
  display: inline-block;
  padding: 4px 9px;
  border-radius: 5px;
}
.siteItemBox{
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 15px;
  /* border-bottom: 1px solid rgba(60, 76, 145, 0.1) */
}
.siteItemBox .siteHead {
 text-align: left;
 background: #0833A3;
 border-bottom: 2px solid #FFC24B;
 font-size: 14px;
  font-family: Arial;
  font-weight: bold;
  color: #FFFFFF;
  padding: 7px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.siteItemBox .siteHead span{
  padding-right: 8px;
}
.siteItemBox .siteHead .flex1 {
  height: 2px;
  flex: 1;
  border-bottom: 1px dashed rgba(255,255,255,0.5);
  position: relative;
}
.siteItemBox .siteHead .flex1:after{
  display: block;
  content: "";
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  right: 0;
}

.siteItem_item{
  padding-top: 12px;
  /* padding-bottom: 12px; */
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.infoItemInner .siteItemBox:last-child{
  border: none;
}

.siteItemItem_name{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #313F87;
  padding: 6px 10px;
  /* background: #D2E5FC; */
  border-radius: 63x;
  margin-right: 12px;
}
.siteItemItem_val{
  flex: 1;
  text-align: left;
  font-size: 12px;
}
.infoItemInner2{
  display: flex;
}
.infoItemInner2 > div {
  width: 50%;
}
.qyImg{
  width: 90%;
  margin: 0 auto 32px;
  border-radius: 10px;
}
.infoBody_msg{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #242426;
  line-height: 21px;
  text-align: left;
  padding: 30px 12px;
}
.infoBody2{
  position: relative;
  top: -10px;
}

.infoBodyList{
  top: 0;
  margin-bottom: 10px;
}


/****/
.line {
		position: absolute;
		left: 0px;
		top: 15px;
		z-index: 1;
		width: 110px;
		height: 20px;
		background: linear-gradient(120deg, #87B4FF 0%, #E3EFFF 100%);
	}

	.name {
		z-index: 9;
		height: 36px;
		background: #FF9F07;
		border: 2px solid #FFF085;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0 10px;
		position: relative;
		top: 20px;
    margin: 0 8px;
	}

	.block {
		width: 8px;
		height: 8px;
		background: #FFFFFF;
		display: inline-block;
		margin-right: 5px;
	}
  .lcImg{
    width: 100%;
    margin-top: 25px;
  }

  .qyTitBox{
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #2E3033;
		padding: 14px 32px;
		background: linear-gradient(92deg, rgba(107, 134, 255, 0.2) 0%, rgba(237, 245, 255, 0.2) 100%);
		position: relative;
		display: flex;
	}
	.qyTitBox::after{
		content: "";
		display: block;
		width: 10px;
		position: absolute;
		top:0;
		left:0;
		bottom: 0;
		background: #3C65E5;
		border-radius: 0px 6px 6px 0px;
	}
  .dtImg{
    width: 96%;
    margin: 10px 10px 20px;
    display: block;
    
  }
  .myVideo{
    width: 100%;
    margin: 25px auto 0px;
    border-radius: 10px;
    overflow: hidden;
  }
  .photoWrap{
    /* width: 95%;
    margin: 0 auto 20px;
    padding: 15px 10px 5px; */
    /* box-sizing: border-box; */
    /* background: #E3EFFF; */
    /* box-shadow: 0px 4px 49px 0px rgba(154,188,230,0.6); */
    /* border: 1px solid #fff; */
    /* border-radius: 10px; */
    /* position: relative;
    top: -10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start; */
    /*column-count: 2; /* 列数 */
    /*column-gap: 10px; /* 列间距 */

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    padding-top: 25px;
  }
  .photoWrap .photoImg{
    /* width: 100%; */
    margin-bottom: 10px;
    
    /* display: inline-block; */
  }
  .photoWrap .photoImg:nth-child(1){
    width: 100%;
  }
  .photoWrap .photoImg:nth-child(2){
    width: 120px;
    height: 195px;
  }
  .photoWrap .photoImg:nth-child(3){
    width: 160px;
    height: 94px;
    /* width: 348px;
    height: 185px; */
  }
  .photoWrap .photoImg:nth-child(4){
    width: 160px;
    height: 94px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    /* width: 350px;
    height: 187px; */
  }
  .fixedBtn{
  }
  .fixedBtnImg {
    width: 103px;
    height: 45px;
    position: absolute;
    right: 10px;
    bottom: 180px;
  }
  .photosPage{
    min-height: 200px;
  }
  .downImg{
    width: 35px;
    height: 35px;
  }
  .logoBox{
    text-align: left;
  }
  .otitleImg{
    width: 40px;
    height: 14px;
  }

  .pb20{
    padding-bottom: 20px;
  }

  .newBody{
    border: 1px solid #fff;
    background: #EDF0FF;
    box-shadow: 0px 0px 10px 10px rgba(154,188,230,0.7) inset;
    width: 90%;
    box-sizing: border-box;
    margin: 0 auto 23px;
    border-radius: 10px;
    padding: 35px 20px;
  }
  .newTitWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    padding-bottom: 23px;
  }
  .newEngTit{
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    color: #707B99;
    padding-bottom: 10px;
    text-align: left;
  }
  .newCheTit{
    font-size: 23px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    text-align: left;
  }
  .infoBody_msg2{
    padding: 25px 12px 0;
  }
  .newTit{
    flex: 1;
    padding-right: 20px;
  }
  .numberImg{
    width: 52px;
    height: 43px;
  }


</style>